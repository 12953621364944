import styled from '@emotion/styled'
import { graphql, PageProps } from 'gatsby'
import React from 'react'
import SEO from '../components/atoms/seo'
import OfficialLayout from '../components/organisms/officialLayout'
import OfficialLowHeader from '../components/organisms/officialLowHeader'

const Wrapper = styled.main`
  padding: 0 0 80px;

  .p-section-inner {
    max-width: 1080px;
    margin: 0 auto;
    padding: 40px 40px 0;

    h3 {
      margin: 0 0 10px;
    }

    p {
      margin: 0 0 20px;
    }

    table {
      width: 100%;
      margin-bottom: 15px;

      th {
        width: 50%;
        background: rgba(200, 200, 200, 0.4);
        text-align: left;
        font-weight: normal;
        padding: 5px;
        border: solid 1px rgba(200, 200, 200, 1);

        &:first-of-type {
          width: 35%;
        }

        &:last-of-type {
          width: 65%;
        }
      }

      td {
        padding: 5px;
        border: solid 1px rgba(200, 200, 200, 1);
        vertical-align: top;
      }
    }
  }
`

type IProps = PageProps<GatsbyTypes.IndexPageQuery>

/**
 * 利用規約[サービスサイト利用規約]
 * @constructor
 */
const ExternaltoolsPage = ({ location }: IProps) => (
  <OfficialLayout location={location}>
    <SEO title="外部送信規律に関する公表事項" />
    <Wrapper>
      <OfficialLowHeader label="外部送信規律に関する公表事項" description=" " />
      <div className="p-section-inner">
        <p>
          当社が提供するサービス「キンクラ」「キンクラコラム」において利用状況の把握や、運用の管理および改善、広告効果の測定を目的として、当社又は第三者が提供する外部送信ツール（SDKや広告サービスのクッキー、情報収集モジュール等）により取得するお客様の情報を外部に送信することがあります。
          <br />
          外部送信ツールの名称、送信される利用者情報の内容、利用目的等の詳細は以下のとおりです。
        </p>
        <h3>キンクラ</h3>
        <table>
          <tr>
            <th>外部送信ツール名称</th>
            <th>Google Analytics</th>
          </tr>
          <tr>
            <td>送信される情報の内容</td>
            <td>
              ・デバイスやブラウザの情報
              <br />
              ・ネットワークの情報
              <br />
              ・操作情報
            </td>
          </tr>
          <tr>
            <td>送信先の情報取扱者</td>
            <td>Google LLC.</td>
          </tr>
          <tr>
            <td>利用目的</td>
            <td>
              ・サービス利用の分析、改善のため
              <br />
              ・広告配信の最適化、効果測定のため
            </td>
          </tr>
        </table>
        <table>
          <tr>
            <th>外部送信ツール名称</th>
            <th>Google Tag Manager</th>
          </tr>
          <tr>
            <td>送信される情報の内容</td>
            <td>
              ・デバイスやブラウザの情報
              <br />
              ・ネットワークの情報
              <br />
              ・操作情報
            </td>
          </tr>
          <tr>
            <td>送信先の情報取扱者</td>
            <td>Google LLC.</td>
          </tr>
          <tr>
            <td>利用目的</td>
            <td>
              ・サービス利用の分析、改善のため
              <br />
              ・広告配信の最適化、効果測定のため
            </td>
          </tr>
        </table>
        <table>
          <tr>
            <th>外部送信ツール名称</th>
            <th>reCAPTCHA</th>
          </tr>
          <tr>
            <td>送信される情報の内容</td>
            <td>
              ・クライアントID
              <br />
              ・操作情報
            </td>
          </tr>
          <tr>
            <td>送信先の情報取扱者</td>
            <td>Google LLC.</td>
          </tr>
          <tr>
            <td>利用目的</td>
            <td>安全なサービス提供のため</td>
          </tr>
        </table>
        <table>
          <tr>
            <th>外部送信ツール名称</th>
            <th>Stripe</th>
          </tr>
          <tr>
            <td>送信される情報の内容</td>
            <td>
              ・クライアントID
              <br />
              ・会社名
              <br />
              ・情報登録者のメールアドレス
            </td>
          </tr>
          <tr>
            <td>送信先の情報取扱者</td>
            <td>Stripe, Inc.</td>
          </tr>
          <tr>
            <td>利用目的</td>
            <td>サービス利用料金のクレジットカード決済のため</td>
          </tr>
        </table>
        <table>
          <tr>
            <th>外部送信ツール名称</th>
            <th>Appsflyer</th>
          </tr>
          <tr>
            <td>送信される情報の内容</td>
            <td>
              ・デバイスやアプリの情報
              <br />
              ・操作情報
            </td>
          </tr>
          <tr>
            <td>送信先の情報取扱者</td>
            <td>Appsflyer Ltd.</td>
          </tr>
          <tr>
            <td>利用目的</td>
            <td>
              ・サービス利用の分析、改善のため
              <br />
              ・広告配信の最適化、効果測定のため
            </td>
          </tr>
        </table>
        <table>
          <tr>
            <th>外部送信ツール名称</th>
            <th>Firebase</th>
          </tr>
          <tr>
            <td>送信される情報の内容</td>
            <td>
              ・デバイスやアプリの情報
              <br />
              ・ネットワークの情報
              <br />
              ・操作情報
            </td>
          </tr>
          <tr>
            <td>送信先の情報取扱者</td>
            <td>Google LLC.</td>
          </tr>
          <tr>
            <td>利用目的</td>
            <td>
              ・サービス提供のため
              <br />
              ・サービス利用の分析、改善のため
            </td>
          </tr>
        </table>
        <table>
          <tr>
            <th>外部送信ツール名称</th>
            <th>Google広告</th>
          </tr>
          <tr>
            <td>送信される情報の内容</td>
            <td>
              ・広告識別子
              <br />
              ・デバイスやブラウザの情報
              <br />
              ・ネットワークの情報
              <br />
              ・操作情報
            </td>
          </tr>
          <tr>
            <td>送信先の情報取扱者</td>
            <td>Google LLC.</td>
          </tr>
          <tr>
            <td>利用目的</td>
            <td>広告配信の最適化、効果測定のため</td>
          </tr>
        </table>
        <h3>キンクラコラム</h3>
        <table>
          <tr>
            <th>外部送信ツール名称</th>
            <th>Google Analytics</th>
          </tr>
          <tr>
            <td>送信される情報の内容</td>
            <td>
              ・デバイスやブラウザの情報
              <br />
              ・ネットワークの情報
              <br />
              ・操作情報
            </td>
          </tr>
          <tr>
            <td>送信先の情報取扱者</td>
            <td>Google LLC.</td>
          </tr>
          <tr>
            <td>利用目的</td>
            <td>
              ・サービス利用の分析、改善のため
              <br />
              ・広告配信の最適化、効果測定のため
            </td>
          </tr>
        </table>
        <table>
          <tr>
            <th>外部送信ツール名称</th>
            <th>Google Tag Manager</th>
          </tr>
          <tr>
            <td>送信される情報の内容</td>
            <td>
              ・デバイスやブラウザの情報
              <br />
              ・ネットワークの情報
              <br />
              ・操作情報
            </td>
          </tr>
          <tr>
            <td>送信先の情報取扱者</td>
            <td>Google LLC.</td>
          </tr>
          <tr>
            <td>利用目的</td>
            <td>
              ・サービス利用の分析、改善のため
              <br />
              ・広告配信の最適化、効果測定のため
            </td>
          </tr>
        </table>
        <table>
          <tr>
            <th>外部送信ツール名称</th>
            <th>Google広告</th>
          </tr>
          <tr>
            <td>送信される情報の内容</td>
            <td>
              ・広告識別子
              <br />
              ・デバイスやブラウザの情報
              <br />
              ・ネットワークの情報
              <br />
              ・操作情報
            </td>
          </tr>
          <tr>
            <td>送信先の情報取扱者</td>
            <td>Google LLC.</td>
          </tr>
          <tr>
            <td>利用目的</td>
            <td>広告配信の最適化、効果測定のため</td>
          </tr>
        </table>
      </div>
    </Wrapper>
  </OfficialLayout>
)

export default ExternaltoolsPage

export const pageQuery = graphql`
  query ExternaltoolsPage {
    site {
      siteMetadata {
        title
      }
    }
  }
`
